import styled from 'styled-components';
import { nafColor } from '@nafcore/theme';
import { Button } from '@naf/button';
import { Text } from '@naf/text';
import { ButtonLink } from '@naf/button-link';
import { breakpoints, radius, spacing } from '@naf/theme';
import { TextList } from '@naf/text-list';
import { GridRow } from '@naf/grid';

export const CTAWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.space16};

  & > a {
    @media (max-width: ${breakpoints.s}) {
      padding: 0 ${spacing.space16};
    }
  }
`;

export const CtaActivateButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledButtonLink = styled(ButtonLink)`
  margin-bottom: ${spacing.space32};
`;

export const DiscountWrapper = styled.div`
  margin-bottom: ${spacing.space32};
`;

export const MobileWrapper = styled.div`
  display: none;
  @media (max-width: ${breakpoints.m}) {
    display: block;
  }
`;

export const DesktopWrapper = styled.div<{ $numberOfBullets?: number }>`
  display: block;
  margin-top: ${({ $numberOfBullets }) =>
    $numberOfBullets && $numberOfBullets < 5 ? spacing.space32 : `${24 * $numberOfBullets}px`};

  @media (max-width: ${breakpoints.m}) {
    display: none;
  }
`;

export const StyledHeaderText = styled(Text)`
  margin: 0;
`;

export const DiscountInfoText = styled(Text)`
  margin-bottom: ${spacing.space32};
`;

export const DiscountText = styled(Text)`
  margin-bottom: ${spacing.space32};
  color: ${nafColor.primary.park};
  font-weight: 900;
`;

export const StyledTextList = styled(TextList)`
  margin-bottom: ${spacing.space32} !important;
`;

export const StyledButton = styled(Button)<{ applyLeftMargin?: boolean }>`
  margin-left: ${(props) => (props.applyLeftMargin ? spacing.space16 : 0)};
`;

export const ReadMoreWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.space8};
`;

export const BannerWrapper = styled.div<{ $restrainWidth?: boolean }>`
  overflow: hidden;
  margin-bottom: ${spacing.space32};

  p {
    margin-bottom: ${spacing.space24};

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${breakpoints.m}) {
    width: 100%;
    ${({ $restrainWidth }) => ($restrainWidth ? 'max-width: 33%;' : '')}
  }

  img {
    max-width: 350px;
  }

  // Styling for tablet
  @media (max-width: ${breakpoints.m}) and (min-width: ${breakpoints.s}) {
    > div {
      flex-direction: row;
      margin-right: ${spacing.space48};
      width: calc(100% - ${spacing.space48});
      margin-top: 0;
      > div:first-child {
        width: 70%;
        padding: 0;
        padding-right: ${spacing.space16};
      }
      > div:last-child {
        order: 0;
        min-height: calc(100% - 2 * ${spacing.space32});
        max-height: calc(100% - 2 * ${spacing.space32});
        max-width: 30%;
        top: 0;
        right: -${spacing.space48};
        padding-top: ${spacing.space24};
        > img {
          object-fit: cover;
        }
      }
    }
  }
`;

export const AppAnnouncementWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid ${nafColor.neutral.neutral3 || '#C1C1C1'};
  padding: ${spacing.space32} ${spacing.space24};
  margin-bottom: ${spacing.space32};
`;

export const AppTextWrapper = styled.div`
  h2 {
    margin-top: 0;
  }
`;

export const AppIllustration = styled.div`
  margin-right: ${spacing.space32};
  @media (max-width: ${breakpoints.s}) {
    width: 100%;
    img {
      max-width: 67px;
    }
  }
`;

export const BenefitWrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 ${spacing.space16};

  @media (min-width: 1440px) {
    padding: 0;
  }
`;

export const BenefitContent = styled.div`
  padding: 0 ${spacing.space64};

  @media (max-width: ${breakpoints.l}) {
    padding: 0;
  }
`;

export const PartnerlogoWrapper = styled.div`
  position: absolute;
  top: -12px;
  left: 16px;
  background-color: ${nafColor.signature.white};
  border-radius: ${radius.s};
  padding: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  box-shadow: 0px 0.76px 1.51px 0px #162f090d, 0px 3.03px 3.03px 0px #162f090a, 0px 6.81px 3.78px 0px #162f0908,
    0px 11.35px 4.54px 0px #162f0903, 0px 18.15px 5.3px 0px #162f0900;

  img {
    height: 32px;

    @media (max-width: ${breakpoints.m}) {
      height: 24px;
    }
  }

  @media (min-width: ${breakpoints.m}) {
    left: ${spacing.space64};
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  margin: ${spacing.space80} auto 0;
  padding: 0;
  width: 100%;

  @media (max-width: ${breakpoints.m}) {
    margin-top: ${spacing.space24};
  }
`;

export const ImageFigure = styled.figure`
  margin: 0;
  position: relative;
  overflow: hidden;
  border-top-left-radius: ${radius.l};
  border-top-right-radius: ${radius.l};

  img {
    width: 100%;
    max-height: 512px;
    object-fit: cover;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  }
`;

export const StyledGridRow = styled(GridRow)`
  padding: 0 ${spacing.space48};

  @media (max-width: ${breakpoints.m}) {
    padding: 0;
  }
`;

export const TopContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${spacing.space16};

  @media (max-width: ${breakpoints.l}) {
    flex-wrap: wrap;
    align-items: flex-start;
  }
`;

export const BenefitDiscountCopyWrapper = styled.div`
  display: flex;
  margin-bottom: ${spacing.space32};
`;

export const CampaignDiscountWrapper = styled.div`
  display: flex;
  gap: ${spacing.space16};
`;
